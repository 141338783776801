import React, {ReactNode} from 'react';
import {AppBarHeight, Palette} from "../constants";
import styled from 'styled-components';
import Cover, {CoverProps} from "./Cover";


export type HeroSectionProps = {children: ReactNode;} & CoverProps;
export function HeroSection({children, ...props}: HeroSectionProps) {
    return <Wrapper>
        <Cover {...props}/>
        {children}
    </Wrapper>;
}

const Wrapper = styled.section`
  display: flex;
  position: relative;
  width: 100%;
  height: 100% ;
  flex-direction: column;
  align-items: center;
  background-color: ${Palette.Default.background};
  justify-content: space-around;
  overflow: hidden;
  min-height: calc(100vh - ${AppBarHeight.desktop}em - 10em);
  
  padding-top: 2em;
  padding-bottom: 2em; 
  border-bottom: 1px solid ${Palette.Yellow.hash};

  
`;
