import * as React from 'react';
import styled from 'styled-components';
import {AppBarHeight, Media, Palette} from "../constants";
// @ts-ignore
import CrossDesktopBackgroundImage from "../images/cover-background-grid.svg";
// @ts-ignore
import NoCrossDesktopBackground from "../images/nocrossbackground.svg";
// @ts-ignore
import MobileBkg1 from "../images/mobileBkg1.svg";
// @ts-ignore
import MobileBkg2 from "../images/mobileBkg2.svg";


export type CoverProps = {
    cross?: boolean;
}
export default function Cover({cross}: CoverProps) {
    return (
        <Container>
            <Image1 cross={cross}/>
            <Image2 cross={cross}/>
        </Container>
    )
}

const Container = styled.div`
  position: absolute ;
  left: 0 ;
  right: 0 ;
  top: 0 ;
  bottom: 0 ;
  width: 100% ;
  min-width: 100vw;
  min-height: calc( 100vh - ${AppBarHeight.desktop}em ) ;
  background-color: black ;
  overflow: hidden ;
  border-bottom: 1px solid ${Palette.Yellow.hash} ;
`;
const Image1 = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;  
  background-size: cover;
  
  background-image: url(${MobileBkg1});
  background-position: top center ;
  filter: blur(10px);
  -webkit-filter: blur(10px);

  @media (${Media.laptop}) {
      background-image: url(${({cross}) => cross ? CrossDesktopBackgroundImage : NoCrossDesktopBackground});
      background-position: top right ;
      filter: unset;    
      -webkit-filter: unset;
  }
`;

const Image2 = styled.div`
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;     
  background-image: url(${MobileBkg2});
  background-position: top center ;
  opacity: 0.6;
  
  @media (${Media.laptop}) {
    display: none;
  }
`;

